const service =[
    {
        title:'spinal surgery',
        image:'https://scontent-los2-1.xx.fbcdn.net/v/t39.30808-6/309535818_131384109644427_4330646357706836881_n.jpg?_nc_cat=106&cb=99be929b-59f725be&ccb=1-7&_nc_sid=8bfeb9&_nc_eui2=AeFN3kapuBIr1E-vZhcFRmTD9SeSRRSx2Rj1J5JFFLHZGMPG-E6ms8TLs4plmmUt6jToRX_cZ33cBkKdbpViIu3D&_nc_ohc=gQiL6Bzo084AX_ekzYW&_nc_ht=scontent-los2-1.xx&oh=00_AfAAl6rZ_6QZrcbMnVsV1ObDml-VOai6Gd9jZQVpiipzEg&oe=64A8BEF8'
    },  
{
    title:'total knee replacement',
    image:'https://z-p3-scontent.fnsi1-2.fna.fbcdn.net/v/t39.30808-6/322238235_489636079913162_3811522321312307567_n.jpg?_nc_cat=102&cb=99be929b-59f725be&ccb=1-7&_nc_sid=8bfeb9&_nc_eui2=AeGA0baAeXSQr0fiuWTSA8pvclPYS4CWhYpyU9hLgJaFismO_eZAIefIO30pefR27sroJtBZpcjKBVcVfDEFDfbE&_nc_ohc=zqG3TXMhxHYAX9rC7N7&_nc_zt=23&_nc_ht=z-p3-scontent.fnsi1-2.fna&oh=00_AfDQYa4vz3jIrtFkEOoRgOvwVCaUx2RA5fJiEuRoFB8nqg&oe=64AA327D'
},
{
    title:'total hip replacement',
    image:'https://scontent-los2-1.xx.fbcdn.net/v/t39.30808-6/322604736_699136695071668_2626955148508957493_n.jpg?_nc_cat=103&cb=99be929b-59f725be&ccb=1-7&_nc_sid=8bfeb9&_nc_eui2=AeFAQ_K4q8Wu-DydBaubr3uc3ra3tVrNr3zetre1Ws2vfIZoXpAyLtMx_0HTaCNBzSrAHZ9WaNwr62vz47g6G3xg&_nc_ohc=Rf7eCrGr8PoAX-3sKKX&_nc_ht=scontent-los2-1.xx&oh=00_AfDOZec6BnP354h2-K-kf1nfa1hbGKYPz74UvPG3vW5rtw&oe=64A7BB51'
},
{
    title:'shoulder joint replacement',
    image:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQa3Rx833qs_eu6vTrFPmAqVb_aSmunwTIxsQ&usqp=CAU  '
},
{
    title:'ankle joint replacement  ',
    image:'https://scontent-los2-1.xx.fbcdn.net/v/t39.30808-6/308175427_137314255679626_5086726040782456875_n.jpg?_nc_cat=110&cb=99be929b-59f725be&ccb=1-7&_nc_sid=8bfeb9&_nc_eui2=AeGlIiPfmWft-NjV0NATjzYwomyy1Lqglh2ibLLUuqCWHXF6Y0LkJoF8wEKcB-abMiYmpOO5TEQvu0lh7o9wq5TM&_nc_ohc=JucsYGMf1EwAX_7k3yw&_nc_ht=scontent-los2-1.xx&oh=00_AfChPHtONsom-aiah5o0om_nY6jZWaGZqDLVQ-oK-H1gVA&oe=64A88491'
},

{
    title:'neglected trauma cases',
    image:'https://cdn.primedia.co.za/primedia-broadcasting/image/upload/c_fill,h_437,w_700/ipbgyjymroevwuelgcdv'
}, 
{
    title:'correction of deformities',
    image:'https://scontent-los2-1.xx.fbcdn.net/v/t39.30808-6/309535818_131384109644427_4330646357706836881_n.jpg?_nc_cat=106&cb=99be929b-59f725be&ccb=1-7&_nc_sid=8bfeb9&_nc_eui2=AeFN3kapuBIr1E-vZhcFRmTD9SeSRRSx2Rj1J5JFFLHZGMPG-E6ms8TLs4plmmUt6jToRX_cZ33cBkKdbpViIu3D&_nc_ohc=gQiL6Bzo084AX_ekzYW&_nc_ht=scontent-los2-1.xx&oh=00_AfAAl6rZ_6QZrcbMnVsV1ObDml-VOai6Gd9jZQVpiipzEg&oe=64A8BEF8'
}, 
]





export default service