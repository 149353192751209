const data=[
    {
    name:"Dr. Suh Florence",
    image:require("../assets/DSC_8811.JPG"),
    description:"Dr. Vena is a medical doctor who specializes in cardiac diseases",
    specialization:"Main Doctor",
    address:"Kolkata, West Bengal",
    phone:"+237 676230402",
    email:"tugrp@example.com",  
    website:"www.vena.com",
    rating:"5",
    review:"Lorem ipsum dolor sit amet, consectetur adipiscing elit",
    },
    {
        name:"Dr Mustu Venasius",
        image:require("../assets/DSC_8809.JPG"),
        description:"Dr. Vena is a medical doctor who specializes in heart des",
        specialization:"Main Doctor",
        address:"Kolkata, West Bengal",
        phone:"+237 673409309",
        email:"tugrp@example.com",  
        website:"www.vena.com",
        rating:"5",
        review:"Lorem ipsum dolor sit amet, consectetur adipiscing elit",
        },
    // {
    //     name:"Dr. gael",
    //     image:require("../assets/DSC_8808.JPG"),
    //     description:"Dr. Vena is a medical doctor who specializes in heart des",
    //     specialization:"Cardiac diseases",
    //     address:"Kolkata, West Bengal",
    //     phone:"0123456789",
    //     email:"tugrp@example.com",
    //     website:"www.vena.com",
    //     rating:"5",
    //     review:"Lorem ipsum dolor sit amet, consectetur adipiscing elit",
    
    // },
    // {
    //     name:"Dr. suh",
    //     image:require("../assets/DSC_8808.JPG"),
    //     description:"Dr. Vena is a medical doctor who specializes in heart des",
    //     specialization:"Cardiac diseases",
    //     address:"Kolkata, West Bengal",
    //     phone:"0123456789",
    //     email:"tugrp@example.com",
    //     website:"www.vena.com",
    //     rating:"5",
    //     review:"Lorem ipsum dolor sit amet, consectetur adipiscing elit",
    
    // },
    // {
    //     name:"Dr. suh",
    //     image:require("../assets/DSC_8808.JPG"),
    //     description:"Dr. Vena is a medical doctor who specializes in heart des",
    //     specialization:"Cardiac diseases",
    //     address:"Kolkata, West Bengal",
    //     phone:"0123456789",
    //     email:"tugrp@example.com",
    //     website:"www.vena.com",
    //     rating:"5",
    //     review:"Lorem ipsum dolor sit amet, consectetur adipiscing elit",
    
    // }
]
export default data