import { Carousel } from "@material-tailwind/react";
 
export default function ImageCarousel() {
  return (
    <Carousel
      className="rounded-xl"
      navigation={({ setActiveIndex, activeIndex, length }) => (
        <div className="absolute bottom-4 left-2/4 z-50 flex -translate-x-2/4 gap-2">
          {new Array(length).fill("").map((_, i) => (
            <span
              key={i}
              className={`block h-1 cursor-pointer rounded-2xl transition-all content-[''] ${
                activeIndex === i ? "bg-white w-8" : "bg-white/50 w-4"
              }`}
              onClick={() => setActiveIndex(i)}
            />
          ))}
        </div>
      )}
    >
       <img
        src='https://scontent-los2-1.xx.fbcdn.net/v/t39.30808-6/308423691_137312945679757_7231177697215063810_n.jpg?_nc_cat=100&cb=99be929b-59f725be&ccb=1-7&_nc_sid=8bfeb9&_nc_eui2=AeHuqwJHwtAhFmXsTTHUMtH6TEf6B_wuDDBMR_oH_C4MMFrJuwvtCP6Jkj9M_QiH2oukXCp20JFyHsSgJv0hlHl0&_nc_ohc=jZzEVgdE_sgAX_SGlcR&_nc_ht=scontent-los2-1.xx&oh=00_AfBwuD5zf04IjgOeBs_kz2jc3E1gzE3Rd6qnkOkfISuVNw&oe=64A71052'
        alt="image 1"
        className="h-full w-full object-cover"
        style={{
          height:550,
          objectFit:'cover'
        }}
      />
      <img
        src="https://scontent-los2-1.xx.fbcdn.net/v/t39.30808-6/301137157_130145569729828_1033236792136690730_n.jpg?_nc_cat=107&cb=99be929b-59f725be&ccb=1-7&_nc_sid=8bfeb9&_nc_eui2=AeExwjF5iMtwTiAl8-aBSCDeNdGJ5AVA9Jk10YnkBUD0mYSn86zQTaaDgp2n18UtakMZf7rRUtRlVNbl0gZtCHaw&_nc_ohc=s-H9ycqBKBUAX8XAZn7&_nc_ht=scontent-los2-1.xx&oh=00_AfA5u8nKmEjqXeoeEOpu2-CFqYZtCVoz1nTKU9U34RyeOA&oe=64A719A1"
        alt="image 2"
        className="h-full w-full object-cover"
      />
      <img
        src="https://scontent-los2-1.xx.fbcdn.net/v/t39.30808-6/301095380_130004706410581_9217443034266091346_n.jpg?_nc_cat=102&cb=99be929b-59f725be&ccb=1-7&_nc_sid=8bfeb9&_nc_eui2=AeHvcBWZ9lGuTkpzsIjabCiLiZCzcD5lClqJkLNwPmUKWs_cxT_jCv52fC2vorC_CgSYZDAh5x88twCPjOGSK-c0&_nc_ohc=Xyr1Du1DpVsAX_oRZUb&_nc_ht=scontent-los2-1.xx&oh=00_AfCCfkgJg5IMNXKopBefWt7hR-zpaVhQVtyDaH1lZqU8jQ&oe=64A7109D"
        alt="image 3"
        className="h-full w-full object-cover"
      />

    </Carousel>
  );
}