import React from 'react'
import data from '../components/data'
import ImageCarousel from '../components/Carrousel'

function About() {
  return (
    <div>
        <div>
          <div className='p-10'>
          <div className='text-center text-5xl items-center '>About Us</div>

<div className='text-center text-lg py-10'>
ST ALESSANDRO CLINIC & UNIVERSITY INSTITUTE DOUALA 
                    created in August 2021 and named after late Professor Alessandro Faldini, a father who has done great things  in the field of orthopedic surgery. Help those who are physically challenged and the underprivileged in developing countries was his concern. We are thus continuing from where he left us. We are out to offer to the population of Cameroon and beyond, high level of medical care and training at affordable cost.
</div>
          </div>
            
            <div className='p-10 bg-[#F1F4FA] '>
              <div className='text-center text-5xl items-center '>Our Teem </div>

              <div className='text-center text-lg py-10'>
              Our team includes doctors, nurses, medical assistants, and other support staff. We all work together to provide you with the highest quality of care. We are passionate about our work and we are committed to helping you achieve your health goals.
              </div>
           
{/* start here */}
        
<div class="container my-15 mx-auto md:px-6">
  <section class="mb-32 text-center">
    <div class="grid gap-x-6 md:grid-cols-3 lg:gap-x-12 py-10">
  {data.map((item)=>(

      <div class="mb-6 lg:mb-0 py-8">
        <div
          class="block rounded-lg bg-white shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-neutral-700">
          <div class="relative overflow-hidden bg-cover bg-no-repeat">
            <img src={item.image} style={{
                height:'350px',
                objectFit:'fill'
                
            }} class="w-full rounded-t-lg" />
            <a href="#!">
              <div class="absolute top-0 right-0 bottom-0 left-0 h-full w-full overflow-hidden bg-fixed"></div>
            </a>
            <svg class="absolute text-white dark:text-neutral-700 left-0 bottom-0" xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1440 320">
              <path fill="currentColor"
                d="M0,288L48,272C96,256,192,224,288,197.3C384,171,480,149,576,165.3C672,181,768,235,864,250.7C960,267,1056,245,1152,250.7C1248,256,1344,288,1392,304L1440,320L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z">
              </path>
            </svg>
          </div>
          <div class="p-6">
            <h5 class="mb-4 text-lg font-bold">{item.name}</h5>
            <p class="mb-4 text-neutral-500 dark:text-neutral-300">{item.specialization}</p>
            <p class="mb-4 text-neutral-500 dark:text-neutral-300">{item.phone}</p>
            <ul class="mx-auto flex list-inside justify-center">
              <a href="#!" class="px-2">
                
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                  class="h-4 w-4 text-primary dark:text-primary-400">
                  <path fill="currentColor"
                    d="M12 0c-6.626 0-12 5.373-12 12 0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23.957-.266 1.983-.399 3.003-.404 1.02.005 2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.874.118 3.176.77.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.576 4.765-1.589 8.199-6.086 8.199-11.386 0-6.627-5.373-12-12-12z" />
                </svg>
              </a>
              <a href="#!" class="px-2">
             
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                  class="h-4 w-4 text-primary dark:text-primary-400">
                  <path fill="currentColor"
                    d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z" />
                </svg>
              </a>
              <a href="#!" class="px-2">
               
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                  class="h-3.5 w-3.5 text-primary dark:text-primary-400">
                  <path fill="currentColor"
                    d="M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z" />
                </svg>
              </a>
            </ul>
          </div>
        </div>
       
      </div>
  ))}
  </div>
</section>
<div>
  <div className='text-center text-3xl py-10' > Gallery</div>
          <ImageCarousel/>
          </div>
</div>

{/* ends here */}

        </div>
        </div>
    </div>
  )
}
export default About