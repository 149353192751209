import React,{useState} from 'react'
import {  NavLink  } from "react-router-dom";
import WhatsApp from 'react-whatsapp';
import logo from '../assets/logo.jpg'
function Header() {
    const handleClick = () => {
        const collapse = document.querySelector("#navbar-collapse");
        collapse.classList.toggle("hidden");
        collapse.classList.toggle("flex");
      };
  return (
    <div className="header-2">
      <nav className="bg-white py-2 md:py-4">
        <div className="container px-4 mx-auto md:flex md:items-center">
          <div className="flex justify-between items-center">
            <NavLink to='/' className="font-bold text-xl text-indigo-600">
             <img src={logo} style={{height:'70px',objectFit:'fill', width:'70px'}} />
            </NavLink>
            <button
              className="border border-solid border-gray-600 px-3 py-1 rounded text-gray-600 opacity-50 hover:opacity-75 md:hidden"
              id="navbar-toggle"
              onClick={handleClick}
            >
              <i className="fas fa-bars"></i>
            </button>
          </div>

          <div
            className="hidden md:flex flex-col md:flex-row md:ml-auto mt-3 md:mt-0"
            id="navbar-collapse"
          >
            <NavLink
              to={'/'}
              className="p-2 lg:px-4 md:mx-2 text-gray-600 rounded hover:bg-[#71a22f] hover:text-gray-700 transition-colors duration-300"
            >
              Home
            </NavLink>
            <NavLink
             to='/services'
              className="p-2 lg:px-4 md:mx-2 text-gray-600 rounded hover:bg-[#71a22f] hover:text-gray-700 transition-colors duration-300"
            >
              Sercices
            </NavLink>
           
            <NavLink
              to={'/about'}
              className="p-2 lg:px-4 md:mx-2 text-gray-600 rounded hover:bg-[#71a22f] hover:text-gray-700 transition-colors duration-300"
            >
              About
            </NavLink>
            <NavLink
              to={'/contact'}
              className="p-2 lg:px-4 md:mx-2 text-gray-600 rounded hover:bg-[#71a22f] hover:text-gray-700 transition-colors duration-300"
            >
              Contact
            </NavLink>
          
            <WhatsApp number="+237650292489" message="Hello World!" className="border-2 border-[#71a22f] hover:bg-[#71a22f] cursor-pointer p-1 rounded-lg ">
                <div className=" fab fa-pinterest text-[#71a22f]  hover:text-white" > Emergency Call </div>
              </WhatsApp>
          </div>
        </div>
      </nav>
    </div>
  )
}

export default Header
