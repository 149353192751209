import './App.css';
import Home from '../src/pages/Home'
import ErrorPage from './components/error';
import {BrowserRouter, Routes, Route} from "react-router-dom";
import { ThemeProvider } from "@material-tailwind/react";
import Header from './components/Header';
import Sercices from './pages/services';
import About from './pages/About';
import Contact from './pages/contact';
import Footer from './components/footer';

function App() {

  return (
    <div className="App">
      <ThemeProvider>
      <BrowserRouter>
        <Header/>
        <Routes>
          <Route path='/' element={<Home/>}/>
          <Route path='/services' element={<Sercices/>}/>
          <Route path='/about' element={<About/>}/>
          <Route path='/contact' element={<Contact/>}/>
          {/* <Route path='*' element={<ErrorPage/>}/> */}
        </Routes>
            <Footer/>
      </BrowserRouter>
      </ThemeProvider>
    </div>
  );
}

export default App;
