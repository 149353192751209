import React from 'react'
import background from '../assets/abtbg.JPG'
import service from '../components/servicedata'


function Sercices() {
  return (
    <div>

        <div 
        className='relative'
        style={{
        backgroundImage:`url(${background})`,
        backgroundSize: 'cover',
        height:500,
        // objectFit:'fill'
        
      }}>
        <div class="absolute bottom-0 px-4 py-3 bg-gray-500/50 w-full">
    <h1 class="text-black font-semibold text-4xl">Our Sercices</h1>
    {/* <p class="text-gray-200">
      I love kittens very much. They are amazing.
    </p> */}
  </div>
      </div>
      <div>

{/* first starts here */}

      <div className='bg-[#F1F4FA] w-full '>
        <div className='lg:flex lg:justify-between items-end p-5   '>
                <div className='text-blue-700'>
                    
                  
                </div>               
            </div>
            <div className='p-10'>
                <span className='text-[#71a22f]  text-2xl '> 01. </span>
                <span className='text-2xl'> MEDICAL SERVICES
                    </span><br/> <span className='text-xl'>Our medical services are designed to provide you with the highest quality care possible. We have a team of experienced and qualified professionals who are dedicated to your health and well-being.</span><br/>
                    <span className='text-xl text-[#71a22f]'> We offer a wide range of Medical services, including: </span>
                    </div>
           <div>
           <div className='p-6 grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-5'>
{/* section */}

              <div className="block rounded-lg bg-white text-center shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-neutral-700">
      <div className="border-b-2 border-neutral-100 px-6 py-3 dark:border-neutral-600 dark:text-neutral-50">
      General Consultation And Treatment
      </div>
      <div className="p-6">
      <img src={require('../assets/consult.jpeg')} alt='med'
                  style={{
                    height:250,
                    width:'100%',
                    resize:'-moz-initial',
                    borderRadius:10
                  }}
                  />    
      </div>
      
    </div>
 {/*section ends  */}

 {/* section */}
 <div className="block rounded-lg bg-white text-center shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-neutral-700">
      <div className="border-b-2 border-neutral-100 px-6 py-3 dark:border-neutral-600 dark:text-neutral-50">
      Maternity Services And Delivery
      </div>
      <div className="p-6">
      <img src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRgCvme5exucAS0VVzdY2nGqeBBMDrF1YujQg&usqp=CAU' alt='med'
                  style={{
                    height:250,
                    width:'100%',
                    resize:'-moz-initial',
                    borderRadius:10
                  }}
                  />    
      </div>
      
    </div>

 {/*section ends  */}
 {/* section */}

 <div className="block rounded-lg bg-white text-center shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-neutral-700">
      <div className="border-b-2 border-neutral-100 px-6 py-3 dark:border-neutral-600 dark:text-neutral-50">
      specialized services
      </div>
      <div className="p-6">
      <img src={require('../assets/bg.JPG')} alt='med'
                  style={{
                    height:250,
                    width:'100%',
                    resize:'-moz-initial',
                    borderRadius:10
                  }}
                  />    
      </div>
      <div className="border-t-2 border-neutral-100 px-6 py-3 dark:border-neutral-600 dark:text-neutral-50">
      <span className='text-xl text-[#71a22f]'> We offer a wide range including: </span>   
                  <ul className='list-disc px-10'> 
                    <li className='py-1 text-lg '> gynaecology</li>
                    <li className='py-1 text-lg '> pediatrics</li>
                    <li className='py-1 text-lg '> general surgery</li>
                    <li className='py-1 text-lg '> orthopedic surgery</li>
                  </ul>
      </div>
    </div>
 {/*section ends  */}
 
             </div>
            </div>
           </div>
{/* ends her */}

{/* first starts here */}

<div className='bg-[#F1F4FA] w-full '>
            <div className='p-10'>
                <span className='text-[#71a22f]  text-2xl '> 02. </span>
                <span className='text-2xl'> ADVANCE BONE SURGERY
                    </span><br/> <span className='text-xl'> Advanced Bone Surgery is a team of experienced and dedicated surgeons who provide comprehensive care for a wide range of bone and joint conditions. Our surgeons are experts in the latest surgical techniques and technologies, and we are committed to providing our patients with the highest quality care possible.</span><br/>
                    <span className='text-xl text-[#71a22f]'> We offer a wide range of Medical services, including: </span>
                    </div>
           <div>

           <div className='p-6 grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-5'>

{service.map((item)=>(
           <div className="block rounded-lg bg-white text-center shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-neutral-700">
      <div className="border-b-2 border-neutral-100 px-6 py-3 dark:border-neutral-600 dark:text-neutral-50 capitalize">
      {item.title}
      </div>
      <div className="p-6">
      <img src={item.image} alt='med'
                  style={{
                    height:250,
                    width:'100%',
                    
                    borderRadius:10,
                    objectFit:'contain'
                  }}
                  />    
      </div>
      
    </div>
))}

             </div>
            </div>
           </div>
{/* ends her */}

{/* third starts here */}

<div className='bg-[#F1F4FA] w-full '>
            <div className='p-10'>
                <span className='text-[#71a22f]  text-2xl '> 03. </span>
                <span className='text-2xl'> TRAINING SERVICES
                    </span><br/> <span className='text-xl'>We have a higher institute for training healthcare workers, food Processing, agriculture engineers and a school of business and management. 
our aim is to offer innovative education for a healthier feature, by problem based learning and practice based learning.</span><br/>
                          <span className='text-xl text-[#71a22f]'> We offer a wide range of Training services, including: </span>
                    </div>
           <div>
           <div className='grid sm:grid-cols-1 text-center md:grid-cols-2 gap-4 lg:grid-cols-3  py-12 px-10 '>
{/* section */}

              <div className="block rounded-lg bg-white p-6 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-neutral-700">
      <h5 className="mb-2 text-xl font-medium leading-tight text-neutral-800 dark:text-neutral-50">
       
      </h5>
      <p className="mb-4 text-base text-neutral-600 dark:text-neutral-200 capitalize">
      We have a one or two years vocational training program under the ministry of vocational training and employment
</p>
      
    </div>
 {/*section ends  */}

 {/* section */}
              <div className="block rounded-lg bg-white p-6 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-neutral-700">
      <h5 className="mb-2 text-xl font-medium leading-tight text-neutral-800 dark:text-neutral-50">
       
      <p className="mb-4 text-base text-neutral-600 dark:text-neutral-200 capitalize">
      a 3 years HND program in the health programs, food Processing and agriculture Engineering, under the ministry of higher education
</p>
      </h5>
      
    </div>
 {/*section ends  */}
 {/* section */}
              <div className="block rounded-lg bg-white p-6 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-neutral-700">
      <h5 className="mb-2 text-xl font-medium leading-tight text-neutral-800 dark:text-neutral-50 h-5">
        
      </h5>
      <p className="mb-4 text-base text-neutral-600 dark:text-neutral-200 capitalize">
      a 2 years program if the fields of business and management under higher education.
      </p>
      
    </div>
 {/*section ends  */}
 <div className="block rounded-lg bg-white p-6 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-neutral-700">
      <h5 className="mb-2 text-xl font-medium leading-tight text-neutral-800 dark:text-neutral-50 h-5">
        
      </h5>
      <p className="mb-4 text-base text-neutral-600 dark:text-neutral-200 capitalize">
    VOCATIONAL TRAINING INSTITUTE ST ALESSANDRO (VTI SA)
      </p>
      
    </div>
             </div>
            </div>
           </div>
{/* ends her */}


      </div>
      
    </div>
  )
}

export default Sercices
